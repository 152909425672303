import React from "react";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom"; // Import hooks
import Navigation from "../components/Navigation";
import ShopifyApiKey from "../components/ShopifyApiKey";

const track = {
  background: `url("/image/track/bg_1.png")`,
  width: "100%",
  backgroundSize: "100% 100%",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  padding: "7% 0%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
};

const ShopifyAuth = () => {
  const { sessionId } = useParams(); // Get sessionId from the URL
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get("redirectUrl");
  console.log("Redirect URL (manual method):", redirectUrl);
  return (
    <>
      <Helmet>
        <title>Tracking Orders</title>
        <meta
          name="description"
          content="Explore our Frequently Asked Questions to gain insights into Truxcargo’s services. Find answers to commonly raised queries about our logistics solutions."
        />
        <meta name="keywords" content="Indian courier services" />
      </Helmet>
      <Navigation />

      <section className="contact_section track_section" style={track}>
        <div className="container-fluid">
          <div className="row">
            <div className=" col-lg-6 col-md-8 col-12 step-part">
              <div className="left-side primum-steps">
                <h4 className="mb-4">Enter Your API Key</h4>
                {/* <p>
                  <strong>Session ID:</strong> {sessionId}
                </p>
                <p>
                  <strong>Redirect URL:</strong> {redirectUrl}
                </p> */}
                {/* Display redirectUrl */}
                <ShopifyApiKey
                  sessionId={sessionId}
                  redirectUrl={redirectUrl}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-12">
              <div className="right_side" style={{ visibility: "hidden" }}>
                <img src="/image/faq/1.png" alt="" className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopifyAuth;
