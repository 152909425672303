import axios from "axios";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate, useSearchParams } from "react-router-dom";

const ShopifyApiKey = ({ sessionId }) => {
  // ✅ Accept sessionId as a prop
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [apiKey, setApiKey] = useState("");
  const [error, setError] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState("");

  // Extract redirectUrl on mount
  useEffect(() => {
    const encodedRedirectUrl = searchParams.get("redirectUrl");
    if (encodedRedirectUrl) {
      setRedirectUrl(decodeURIComponent(encodedRedirectUrl));
    }
  }, [searchParams]);

  // Watch for sessionId changes
  useEffect(() => {
    console.log("Updated sessionId:", sessionId);
  }, [sessionId]);

  const handleTrackClick = () => {
    fetchData(apiKey);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleTrackClick();
    }
  };

  const handleInputChange = (e) => {
    setApiKey(e.target.value.trim());
  };

  const fetchData = async (key) => {
    try {
      const response = await axios.get(
        `https://b2b.truxcargo.com/api/shopify/authenticate/${key}`
      );

      console.log("response", response);

      if (response.data?.status === true) {
        postData(key);
      } else if (
        response.data?.status === false &&
        response.data?.message === "Incorrect KEY"
      ) {
        setError("Incorrect KEY");
      } else {
        setError("Invalid API Key");
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setError("An error occurred while verifying the API Key.");
    }
  };

  const postData = async (keyId) => {
    try {
      const url = "https://b2b.truxcargo.com/api/shopify/auth";
      const body = {
        sid: sessionId, // ✅ Use updated sessionId
        key: keyId,
      };
      console.log("body", body);

      const response = await axios.post(url, body);
      console.log("response", response);

      if (response.data?.status === true) {
        if (redirectUrl) {
          console.log("Redirecting to:", redirectUrl);
          window.location.href = redirectUrl;
          setError(null);
        } else {
          navigate("/shopify_auth");
        }
      } else if (
        response.data?.status === false &&
        response.data?.message === "Already exists Session Id"
      ) {
        console.log("Already exists Session Id");
        setError("Already exists Session Id");
      }
    } catch (error) {
      console.error("Error posting data", error);
      setError("Failed to authenticate. Please try again.");
    }
  };
  console.warn("error", error);

  return (
    <section className="my-4">
      <div className="track-shipments">
        <Form className="d-flex">
          <div className="w-100">
            <div className="tracking_shipment">
              <input
                type="text"
                placeholder="Please enter your API key"
                value={apiKey}
                className="form-control w-100"
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                required
              />
              <button
                type="button"
                className="button-34"
                onClick={handleTrackClick}
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
      {error && (
        <p
          className="error-message"
          style={{ marginLeft: "2%", marginTop: "1%", color: "black" }}
        >
          {error}
        </p>
      )}
    </section>
  );
};

export default ShopifyApiKey;
